<template>
  <div class="flex flex-col rounded-md p-8" :class="showBorder ? 'border' : ''">
    <h1 v-if="showTitle" :level="2" class="text-2xl text-primary-1000 text-center font-bold uppercase mb-8">
      {{ t('pages.login.title') }}
    </h1>

    <FormKit :incomplete-message="false" id="loginForm" type="form" :actions="false" @submit="handleLogin">
      <FormKit type="email" name="email" validation="required" :label="t('forms.common.email')" v-model="email" />
      <FormKit
        v-if="showSignInManually"
        type="password"
        name="password"
        validation="required"
        :label="t('forms.common.password')"
        v-model="password"
      />
      <div v-if="showSignInManually" class="flex ml-auto">
        <NuxtLink
          :to="generateLink('/forgot-password')"
          class="text-primary-1000 flex ml-auto text-sm hover:underline"
          >{{ t('pages.login.forgot_password') }}</NuxtLink
        >
      </div>
      <CommonButton
        type="submit"
        color="primary"
        size="lg"
        class="mt-4 w-full flex text-center items-center justify-center mx-auto !capitalize"
      >
        <span v-if="!isAuthenticating">
          {{ showSignInManually ? t('common.login') : t('common.login_with_email') }}
        </span>
        <Icon name="tabler:loader-2" size="20" class="animate-spin" v-if="isAuthenticating" />
      </CommonButton>
      <div v-if="!showSignInManually" class="text-sm bg-primary-100 p-2 rounded-lg mt-4">
        <Icon name="mdi:stars" size="18" class="text-primary-1000" /> {{ t('pages.login.magic_link_desc') }}
        <button class="text-primary-1000 font-bold hover:underline" @click="showSignInManually = true">
          {{ t('pages.login.sign_manually') }}
        </button>
      </div>

      <SharedAlert v-if="alertMessage.show" :type="alertMessage.type" :message="alertMessage.message" />

      <div class="flex items-center my-4">
        <hr class="bg-primary-400 w-full" />
        <span class="mx-2 text-sm uppercase">{{ t('common.or') }}</span>
        <hr class="bg-primary-400 w-full" />
      </div>
      <div>
        <button
          type="button"
          class="relative mt-4 w-full flex items-center justify-center mx-auto border rounded-md py-2 text-primary-1000 font-medium border-primary-1000 transition-all hover:border-primary-400 hover:text-primary-400"
          @click="handleSocialLogin('google')"
        >
          <div class="absolute left-5">
            <Icon name="tabler:brand-google" size="24" class="mr-2" />
          </div>
          {{ t('common.login_with_google') }}
        </button>
        <button
          type="button"
          class="relative mt-4 w-full flex items-center justify-center mx-auto border rounded-md py-2 text-primary-1000 font-medium border-primary-1000 transition-all hover:border-primary-400 hover:text-primary-400"
          @click="handleSocialLogin('facebook')"
        >
          <div class="absolute left-5">
            <Icon name="tabler:brand-facebook" size="24" />
          </div>
          {{ t('common.login_with_facebook') }}
        </button>

        <!-- <button
          type="button"
          class="relative mt-4 w-full flex items-center justify-center mx-auto border rounded-md py-2 text-primary-1000 font-medium border-primary-1000 transition-all hover:border-primary-400 hover:text-primary-400"
          @click="handleSocialLogin('apple')"
        >
          <div class="absolute left-5">
            <Icon name="tabler:brand-apple" size="24" />
          </div>
          Login with Apple
        </button> -->
      </div>

      <div class="flex flex-col text-center items-center mt-4">
        <p class="text-sm">{{ t('pages.login.no_account') }}</p>
        <NuxtLink :to="generateLink('register')" class="text-primary-1000 flex text-sm font-bold hover:underline">
          {{ t('common.sign_up') }}
        </NuxtLink>
      </div>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  showTitle: {
    type: Boolean,
    default: true,
  },
  showBorder: {
    type: Boolean,
    default: true,
  },
  loginRedirect: {
    type: String,
    default: 'account',
  },
});

const { t } = useI18n();
const gtm = useGtm();

const authStore = useAuthStore();
const alertStore = useAlertStore();

const { login, loginWithMagicLink, authenticateSocialLogin } = authStore;
const { isAuthenticating } = storeToRefs(authStore);
const { alertMessage } = storeToRefs(alertStore);

const email = ref('');
const password = ref('');

const showSignInManually = ref(false);

const handleLogin = async () => {
  if (showSignInManually.value) {
    await login(email.value, password.value, props.loginRedirect);
    gtm?.trackEvent({
      event: 'login',
      method: 'email',
    });
  } else {
    await loginWithMagicLink(email.value);
    gtm?.trackEvent({
      event: 'login',
      method: 'magic_link',
    });
  }
};

const handleSocialLogin = async (service: string) => {
  gtm?.trackEvent({
    event: 'login',
    method: service,
  });

  try {
    const authUrl = getAuthUrl(service);
    if (!authUrl) throw new Error('Invalid service provider');

    const { code } = await openAuthWindow(authUrl);
    if (code) {
      await authenticateSocialLogin(service, code);
    } else {
      throw new Error('No authentication code received');
    }
  } catch (error) {
    console.error(error);
  }
};

const openAuthWindow = (url: string) => {
  return new Promise((resolve, reject) => {
    const width = 600;
    const height = 700;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const authWindow = window.open(url, '_blank', `width=${width},height=${height},top=${top},left=${left}`);

    const cleanup = () => {
      window.removeEventListener('message', handleMessage);
      clearInterval(interval);
    };

    const interval = setInterval(() => {
      if (!authWindow || authWindow.closed) {
        cleanup();
        reject('Authentication window closed by user');
      }
    }, 1000);

    const handleMessage = (event: any) => {
      if (event.origin !== window.origin) {
        cleanup();
        reject('Origin not allowed');
        return;
      }
      if (event.data && event.data.code) {
        cleanup();
        resolve(event.data);
      }
    };

    window.addEventListener('message', handleMessage);
  });
};

function getAuthUrl(service: string) {
  return authStore.OAuth_Providers[service];
}

onUnmounted(() => {
  authStore.clearAlert();
});
</script>
